<template >
  <b-avatar variant="primary" :size="size" :src="avatarUrl()"></b-avatar>
</template>

<script>
import Helper from "@/models/helper";

export default {
  name: "avatar",
  props: ["value", "size"],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    avatarUrl() {
      return Helper.userAvatar(this.value);
    },
  },
};
</script>

