import axios from 'axios';

class CompanyService {
    getAll() {
        return axios.get('Company/GetAll');
    }
    getCompanyDetail(id) {
        const params = {
            id: id,
        };

        return axios.get('Company/GetCompanyDetail', { params: params });
    }
    saveCompany(data) {
        if (data.id) {
            return this.editCompany(data);
        } else {
            return this.addCompany(data)
        }
    }
    addCompany(data) {
        return axios.post('Company/CreateCompany', data);
    }

    editCompany(data) {
        return axios.post('Company/UpdateCompany', data);
    }

    get() {
        return axios.get('Company/GetCompany');
    }

    getCompanySettings() {
        return axios.get('Company/GetCompanySettings');
    }

    getCompanyInfo(config) {
        return axios.get('Company/GetCompany', config);
    }

    editCompanyInfo(data) {
        return axios.put('Company/SaveCompanyInfo', data)
    }

    saveSettings(data) {
        return axios.put('Company/SaveSettings', data)
    }

    sendTestEmail(data) {
        return axios.post('Company/SendTestEmail', data)
    }

    getPaymentSettings() {
        return axios.get('Company/GetPaymentSettings');
    }

    getOrderSettings() {
        return axios.get('Company/GetOrderSettings');
    }

    assignServiceUserToCompany(companyId) {
        return axios.post('Company/AssignServiceUserToCompany', null, {params: {companyId: companyId}});
    }

    saveLicense(data){
        return axios.post('Company/SaveLicense', data);
    }

    getAllTariffPlans() {
        return axios.get('Company/GetAllTariffPlans');
    }
}

export default new CompanyService();