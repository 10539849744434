import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/theme/theme';
import './assets/theme/style.css';
import './assets/theme/animate.css';
import './assets/fonts/font-awesome/css/font-awesome.css';
import './assets/theme/plugins/metisMenu/jquery.metisMenu';
import './assets/theme/plugins/slimscroll/jquery.slimscroll';
import './assets/style.css';
import InputMask from 'vue-input-mask';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import Vuelidate from 'vuelidate';
import VueToastr from 'vue-toastr';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import moment from 'moment';
import FullCalendar from 'vue-full-calendar';
import DadataSuggestions from 'vue-dadata-suggestions';
import VueNumeric from 'vue-numeric';
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import VueApexCharts from 'vue-apexcharts';
import './registerServiceWorker';
import Avatar from "@/components/Avatar";
import VueMask from 'v-mask';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vueDebounce from 'vue-debounce'
import _ from 'lodash';

Vue.prototype.$http = Axios;
Vue.prototype.$_ = _;

Axios.defaults.baseURL = process.env.VUE_APP_APIPath;

Vue.component('input-mask', InputMask);
Vue.component('date-picker', DatePicker);
Vue.component('multiselect', Multiselect);
Vue.component('apexchart', VueApexCharts);
Vue.component('avatar', Avatar);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(FullCalendar);
Vue.use(VueNumeric);
Vue.use(VueFileAgent);
Vue.use(DadataSuggestions, {
    token: 'f8b95588116403c3ab0616837e9cc0c8be0e0a3c',
    type: 'ADDRESS',
    constraints: {
        locations: { country: "*" }
    }
});
Vue.use(VueToastr, {
    // defaultPosition: "toast-bottom-right",
    // defaultClassNames : ["animated", "zoomInUp"]
    /* OverWrite Plugin Options if you need */
});
Vue.use(VueApexCharts);
Vue.use(VueMask);

Vue.use(VueSweetalert2,{
    confirmButtonColor: '#129399',
    cancelButtonColor: '#ff7674',
  });
Vue.config.productionTip = false;
Vue.use(vueDebounce);

moment.locale("ru");

Vue.filter('hidePhoneNumber', function(value) {
    var l1 = value.substring(0, 2)
    var l2 = value.substring(2, value.length - 5)
    var l3 = value.substring(value.length - 5, value.length)
    return l1 + l2.replace(/\d/g, "*") + l3;
});

Vue.filter('utcToLocalDateTime', function(value) {
    return moment.utc(value);
});

Vue.filter('formatTime', function(value) {
    if (value) {
        return moment(String(value)).format('HH:mm:ss')
    } else {
        return ""
    }
});

Vue.filter('formatTimeNoSeconds', function(value) {
    if (value) {
        return moment(String(value)).format('HH:mm')
    } else {
        return ""
    }
});

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
    } else {
        return ""
    }
});

Vue.filter('formatDateDayMonth', function(value) {
    if (value) {
        return moment(String(value)).format('DD MMMM')
    } else {
        return ""
    }
});

Vue.filter('formatDateDayMonthYear', function(value) {
    if (value) {
        return moment(String(value)).format('DD MMMM YYYY')
    } else {
        return ""
    }
});

Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
    } else {
        return ""
    }
});

Vue.filter('formatDateTimeNoSeconds', function(value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm')
    } else {
        return ""
    }
});

Vue.filter('formatMonthYear', function(value) {
    if (value) {
        return moment(String(value)).format('MMMM YYYY')
    } else {
        return ""
    }
});

Vue.filter('toFixed2', function(value) {
    if (value == null)
        return 0;
    return value.toFixed(2);
});

Vue.filter('toFixedCount', function(value) {
    if (value == null)
        return 0;
    return parseFloat(value.toFixed(2));
});

Vue.filter('thousend', function(value) {
    if (!value)
        return 0;
    return `${value}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ');
});

Stimulsoft.Base.StiLicense.loadFromFile("stimulsoft/license_1.key");
Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("stimulsoft/localization/ru.xml", true);
Stimulsoft.Base.StiFontCollection.addFontFile("fonts/TimesNewRomanRegular.ttf");
Stimulsoft.Base.StiFontCollection.addFontFile("fonts/TimesNewRomanBold.ttf");
Stimulsoft.Base.StiFontCollection.addFontFile("fonts/TimesNewRomanItalic.ttf");
Stimulsoft.Base.StiFontCollection.addFontFile("fonts/TimesNewRomanBoldItalic.ttf");
 Stimulsoft.Base.StiFontCollection.addFontFile("fonts/ArialMT.ttf");
 Stimulsoft.Base.StiFontCollection.addFontFile("fonts/Arial-BoldMT.ttf");
 Stimulsoft.Base.StiFontCollection.addFontFile("fonts/Arial-ItalicMT.ttf");
 Stimulsoft.Base.StiFontCollection.addFontFile("fonts/Arial-BoldItalicMT.ttf");
StiOptions.Engine.barcodeQRCodeAllowUnicodeBOM = false;

Vue.config.devtools = true;
Vue.config.debug = false;
Vue.config.silent = true;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')