<template>
<div></div>
  <!--<div class="footer">
       <div class="pull-right">
        10GB of
        <strong>250GB</strong> Free.
      </div>
      <div>
        <strong>Copyright</strong> Example Company &copy; 2014-2018
      </div>
    </div> -->
</template>

<script>
export default {
  name: "Footer",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
