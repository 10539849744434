import AuthService from '../services/auth.service';
import jwt_decode from 'jwt-decode'

const authInfo = JSON.parse(localStorage.getItem('auth-info'));
const userInfo = JSON.parse(localStorage.getItem('user-info'));
const initialState = authInfo ? { status: { loggedIn: true }, access_token: authInfo.access_token, user: userInfo } : { status: {}, access_token: null, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                authInfo => {
                    commit('loginSuccess', authInfo);
                    return Promise.resolve(authInfo);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error.response.data);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        updateUser({ commit }, user) {
            commit('updateUser', user);
        },
        updateAvatar({ commit }, avatar) {
            commit('updateAvatar', avatar);
        }

    },
    mutations: {
        loginSuccess(state, authInfo) {
            state.status = { loggedIn: true };
            state.authInfo = authInfo;
            let jwt = jwt_decode(authInfo.access_token);

            let userRoles = [];
            if (Array.isArray(jwt.role)) {
                userRoles = jwt.role;
            } else {
                userRoles.push(jwt.role);
            }

            state.user = {
                id: jwt.sub,
                login: jwt.preferred_username,
                name: jwt.name,
                timeZone: jwt.timeZone,
                email: jwt.email,
                role: userRoles,
                avatar: jwt.avatar ? jwt.avatar : null
            }
            localStorage.setItem('user-info', JSON.stringify(state.user));
        },
        loginFailure(state) {
            state.status = {};
            state.authInfo = null;
            state.user = null;
        },
        logout(state) {
            state.status = { loggedIn: false };
            state.authInfo = null;
            state.user = null;
        },
        updateUser(state, user) {
            state.user.name = user.fio;
            state.user.timeZone = user.timeZone + "";
            state.user.email = user.email;
            localStorage.setItem('user-info', JSON.stringify(state.user));
        },
        updateAvatar(state, avatar) {
            state.user.avatar = avatar;
            localStorage.setItem('user-info', JSON.stringify(state.user));
        }
    }
};